import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import VersionSelect from './VersionSelectField';
import { SelectChangeEvent } from '@mui/material';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';
import {
  displayJSONDiffCheckProductAttributesModal,
  setCurrentProductVersionIndex,
  setPreviousProductVersionIndex,
} from 'actions';
import JSONDiffSection from './JSONDIFFSection';
import useProductSlices from 'components/Product/Redux/Slices/useProductSlices';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type VersionSelectionModalProps = {
  open: boolean;
  onClose: () => void;
};

export type HashHistory = {
  hash: string;
  productId: string;
  timestamp: Date;
};

const VersionSelectionModal: React.FC<VersionSelectionModalProps> = ({
  open,
  onClose,
}) => {
  const [compareClicked, setCompareClicked] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const {
    selectedCurrentProductVersionIndex,
    selectedPreviousProductVersionIndex,
    productHistoryInJSON,
    currentProduct,
  } = useProductSlices();

  useEffect(() => {
    if ((productHistoryInJSON as any).length > 1) {
      const defaultCurrentIndex = (productHistoryInJSON as any).length - 1;
      const defaultPreviousIndex = (productHistoryInJSON as any).length - 2;
      dispatch(setCurrentProductVersionIndex(defaultCurrentIndex));
      dispatch(setPreviousProductVersionIndex(defaultPreviousIndex));
    }
  }, [productHistoryInJSON]);

  const handleProceed = () => {
    dispatch(displayJSONDiffCheckProductAttributesModal());
    setCompareClicked(true);
  };

  const sortedProductHistory = [...(productHistoryInJSON as any)].sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const getLabelForIndex = (index: number) =>
    `V${sortedProductHistory.length - 1 - index}`;

  const formatDate = (date: Date) => {
    return dayjs(date)
      .tz((currentProduct as any)?.timezone)
      .format('YYYY-MM-DD HH:mm:ss Z');
  };

  const createSelectItems = (history: HashHistory[]) =>
    history.map((item, index) => ({
      key: index.toString(),
      value: index.toString(),
      label: `${getLabelForIndex(index)} - Date: ${formatDate(item.timestamp)}`,
    }));

  const handleCurrentVersionChange = (e: SelectChangeEvent<string>) => {
    const selectedIndex = Number(e.target.value);
    const reversedIndex = sortedProductHistory.length - 1 - selectedIndex;
    dispatch(setCurrentProductVersionIndex(reversedIndex));
  };

  const handlePreviousVersionChange = (e: SelectChangeEvent<string>) => {
    const selectedIndex = Number(e.target.value);
    const reversedIndex = sortedProductHistory.length - 1 - selectedIndex;
    dispatch(setPreviousProductVersionIndex(reversedIndex));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="version-select-dialog"
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="version-select-dialog">Product history</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            overflow: 'visible',
            marginTop: 2,
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            justifyContent: 'space-between',
          }}
        >
          <VersionSelect
            labelId="version-1-label"
            id="past-version"
            label="Past version"
            value={(
              sortedProductHistory.length -
              1 -
              (selectedPreviousProductVersionIndex as number)
            ).toString()}
            onChange={handlePreviousVersionChange}
            items={createSelectItems(sortedProductHistory)}
          />

          <VersionSelect
            labelId="version-2-label"
            id="current-version"
            label="Current version"
            value={(
              sortedProductHistory.length -
              1 -
              (selectedCurrentProductVersionIndex as number)
            ).toString()}
            onChange={handleCurrentVersionChange}
            items={createSelectItems(sortedProductHistory)}
          />
        </Box>

        {compareClicked && (
          <Box mt={3}>
            <JSONDiffSection />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px', marginTop: '5px' }}>
        <Button onClick={onClose}>Cancel</Button>
        {!compareClicked && (
          <Button onClick={handleProceed} color="primary" variant="contained">
            Compare
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default VersionSelectionModal;
