/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { useTheme } from '@emotion/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';

import MuiTextField from '@mui/material/TextField';
import { Formik, Form, Field } from 'formik';
import { TextField, fieldToTextField } from 'formik-mui';
import OrganizationMultipleSelect from './OrganizationMultipleSelect';
import BackButton from '../commons/BackButton/BackButton';

import { sanitize } from '../../utils/text';

import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import { withRouter } from '../../utils/migrationHelper';
import organizationIcon from '../../assets/images/icons/company.png';
import { withTranslation } from 'react-i18next';

import {
  createOrganizationStart,
  updateOrganizationStart,
  archiveOrganizationStart,
  restoreOrganizationStart,
  getOrganizationStart,
  getOrganizationFormStart,
  getOrganizations,
  resetCurrentOrganization,
  getParentOrganizations,
  organizationPictureChanged,
  setOrganizationType,
  authorizedExtensions,
  authorizedExtensionMessage,
} from '../../actions';
import { enums } from '../../enums';
import { checkUserIsSuperAdmin } from '../../utils/user/checkAdmin/CheckAdmin';

let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

/**
 * Common component import
 */
import LoadingCircle from '../commons/LoadingCircle';
import { checkUserHasRight } from 'utils/user/checkRights';
import { RightReferences } from 'enums/RightReferences';
import { CrudPermissions } from 'enums/CrudPermissions';
import { useOrganizationLogo } from 'components/Drawer/utils/logo';

function SetIdentifierTextField(props) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;

  const { updatingOrganization, ...fieldProps } = props;

  const onChange = React.useCallback(
    (event) => {
      if (!updatingOrganization)
        setFieldValue(
          'identifier',
          event.target.value ? sanitize(event.target.value) : ''
        );
      setFieldValue(name, event.target.value);
    },
    [setFieldValue, name]
  );

  return <MuiTextField {...fieldToTextField(fieldProps)} onChange={onChange} />;
}

let FormOrganization = (props) => {
  const {
    currentOrganization,
    selectedToMakeBusinessWithOrganizations,
    parentOrganizations,
    loading,
    loadingForm,
    localOrganizationPicture,
    isAuthenticated,
    user,
    organizationFormError,
    t,
    cookies,
  } = props;

  let navigate = useNavigate();
  const classes = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  let params = props.router?.params;

  useEffect(() => {
    async function initComponent() {
      const { isAuthenticated, user } = props;

      if (
        checkUserHasRight(
          isAuthenticated,
          user,
          RightReferences.ORGANIZATIONS,
          [CrudPermissions.UPDATE]
        ) ||
        checkUserIsSuperAdmin(isAuthenticated, user)
      ) {
        if (params.organizationId) {
          await props.getOrganizationFormStart(params.organizationId);
        } else {
          props.resetCurrentOrganization();
        }
        await props.getParentOrganizations();
        await props.getOrganizations();
      } else {
        navigate('/');
      }
    }
    initComponent();
  }, [params.organizationId]);

  const isEditMode = Boolean(params.organizationId);
  const { organizationPicture: organizationSelectedPicture } =
    useOrganizationLogo(isEditMode);

  const handlePictureChange = (event) => {
    props.organizationPictureChanged(event.target.files[0]);
  };

  let csrfToken = cookies.cookies._csrf;

  if (loadingForm || !currentOrganization) {
    return (
      <div>
        <LoadingCircle />
      </div>
    );
  }
  let updatingOrganization = false;
  if (params?.organizationId) {
    updatingOrganization = true;
  }

  let isSuperAdmin = false;
  if (checkUserIsSuperAdmin(isAuthenticated, user)) isSuperAdmin = true;

  let userOrganization = user.user_metadata.organization;

  return (
    <>
      <BackButton />
      <Card>
        <CardHeader
          avatar={<img src={organizationIcon} style={{ maxHeight: 20 }} />}
          title={t('Organization information')}
        />
        <CardContent>
          <Formik
            initialValues={{
              name:
                currentOrganization &&
                currentOrganization.name &&
                updatingOrganization
                  ? currentOrganization.name
                  : '',
              identifier:
                currentOrganization &&
                currentOrganization.identifier &&
                updatingOrganization
                  ? currentOrganization.identifier
                  : '',
              type:
                currentOrganization &&
                currentOrganization.type &&
                updatingOrganization
                  ? currentOrganization.type
                  : enums.OrganizationTypes.INDEPENDANT,
              parentOrganization:
                currentOrganization &&
                currentOrganization.parentOrganization &&
                currentOrganization.parentOrganization._id &&
                updatingOrganization
                  ? currentOrganization.parentOrganization._id
                  : '',
              contactFirstName:
                currentOrganization &&
                currentOrganization.contactFirstName &&
                updatingOrganization
                  ? currentOrganization.contactFirstName
                  : '',
              contactLastName:
                currentOrganization &&
                currentOrganization.contactLastName &&
                updatingOrganization
                  ? currentOrganization.contactLastName
                  : '',
              contactEmail:
                currentOrganization &&
                currentOrganization.contactEmail &&
                updatingOrganization
                  ? currentOrganization.contactEmail
                  : '',
              contactPhoneNumber:
                currentOrganization &&
                currentOrganization.contactPhoneNumber &&
                updatingOrganization
                  ? currentOrganization.contactPhoneNumber
                  : '',
              unit:
                currentOrganization && currentOrganization.unit
                  ? currentOrganization.unit
                  : displayPlatinumUI
                  ? enums.Units.GRAMS
                  : enums.Units.KWH,
            }}
            validate={(values) => {
              const errors = {};

              dispatch(setOrganizationType(values.type));

              if (!values.name) {
                errors.name = 'Required';
              } else if (values.name.length < 3) {
                errors.name = 'Name must be at least 3 characters';
              }

              if (!isSuperAdmin && !values.type) {
                errors.type = 'Required';
              }

              if (
                values.contactFirstName &&
                values.contactFirstName.length < 3
              ) {
                errors.contactFirstName =
                  'Contact firstname must be at least 3 characters';
              }

              if (values.contactLastName && values.contactLastName.length < 3) {
                errors.contactLastName =
                  'Contact lastname  must be at least 3 characters';
              }

              if (
                values.contactEmail &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(
                  values.contactEmail
                )
              ) {
                errors.contactEmail = 'Invalid email address';
              }

              let phoneno = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
              if (
                values.contactPhoneNumber &&
                !phoneno.test(String(values.contactPhoneNumber).toLowerCase())
              ) {
                errors.contactPhoneNumber = 'Invalid phone number';
              }

              return errors;
            }}
            onSubmit={(values) => {
              let currentOrganization = values;
              if (params?.organizationId) {
                currentOrganization._id = params.organizationId;
              }

              //We force the organization type, always INDEPENDANT
              currentOrganization.type = enums.OrganizationTypes.INDEPENDANT;
              currentOrganization.parentOrganization = null;

              currentOrganization.businessOrganizations =
                selectedToMakeBusinessWithOrganizations;

              updatingOrganization
                ? props.updateOrganizationStart(
                    currentOrganization._id,
                    currentOrganization,
                    localOrganizationPicture,
                    navigate
                  )
                : props.createOrganizationStart(
                    currentOrganization,
                    localOrganizationPicture,
                    navigate,
                    location && location.state ? location.state : null
                  );
            }}
          >
            {({ submitForm, values, isSubmitting, errors }) => {
              let formCurrentOrganization = values;
              return (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <input
                          type="hidden"
                          name="_csrf"
                          value={{ csrfToken }}
                        />
                        <Field
                          style={{ width: 300, marginTop: 20 }}
                          component={SetIdentifierTextField}
                          label={t('Name')}
                          name="name"
                          type="text"
                          updatingOrganization={updatingOrganization}
                        />
                        {organizationFormError &&
                          organizationFormError.name && (
                            <div>
                              <span style={{ fontSize: 10, color: 'red' }}>
                                {organizationFormError.name}
                              </span>
                            </div>
                          )}
                      </Grid>

                      {isSuperAdmin && (
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <Field
                            disabled={true}
                            style={{ width: 300, marginTop: 20 }}
                            component={TextField}
                            type="text"
                            label="Identifier"
                            name="identifier"
                          />
                          {organizationFormError &&
                            organizationFormError.identifier && (
                              <div>
                                <span style={{ fontSize: 10, color: 'red' }}>
                                  {organizationFormError.identifier}
                                </span>
                              </div>
                            )}
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={3}
                        >
                          <input
                            accept={authorizedExtensions}
                            id="upload-button-file"
                            multiple
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handlePictureChange.bind(this)}
                          />
                          <Grid item>
                            <label htmlFor="upload-button-file">
                              <Button
                                variant="outlined"
                                color="primary"
                                component="span"
                                disabled={loading}
                                style={{
                                  marginTop: 13,
                                  border: '1px solid rgba(248,119,51, 0.5)',
                                  borderRadius: 4,
                                }}
                              >
                                Select Picture
                              </Button>
                            </label>
                            <Typography color="inherit" variant="caption">
                              {authorizedExtensionMessage}
                            </Typography>
                          </Grid>

                          <Grid item>
                            {organizationSelectedPicture && (
                              <img
                                src={organizationSelectedPicture}
                                style={{
                                  height: 60,
                                  width: 60,
                                  borderRadius: '50%',
                                }}
                              />
                            )}
                            {!organizationSelectedPicture && (
                              <Typography
                                color="inherit"
                                variant="caption"
                                style={{ marginTop: 23 }}
                              >
                                No picture selected
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={TextField}
                      type="text"
                      label="Contact firstname"
                      name="contactFirstName"
                    />
                    {organizationFormError &&
                      organizationFormError.contactFirstName && (
                        <div>
                          <span style={{ fontSize: 10, color: 'red' }}>
                            {organizationFormError.contactFirstName}
                          </span>
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={TextField}
                      type="text"
                      label="Contact lastname"
                      name="contactLastName"
                    />
                    {organizationFormError &&
                      organizationFormError.contactLastName && (
                        <div>
                          <span style={{ fontSize: 10, color: 'red' }}>
                            {organizationFormError.contactLastName}
                          </span>
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={TextField}
                      type="text"
                      label="Phone number"
                      name="contactPhoneNumber"
                    />
                    {organizationFormError &&
                      organizationFormError.contactPhoneNumber && (
                        <div>
                          <span style={{ fontSize: 10, color: 'red' }}>
                            {organizationFormError.contactPhoneNumber}
                          </span>
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={TextField}
                      type="email"
                      label="Contact email"
                      name="contactEmail"
                    />
                    {organizationFormError &&
                      organizationFormError.contactEmail && (
                        <div>
                          <span style={{ fontSize: 10, color: 'red' }}>
                            {organizationFormError.contactEmail}
                          </span>
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Field
                      component={TextField}
                      type="text"
                      name="unit"
                      label="Unit"
                      select
                      variant="standard"
                      margin="normal"
                      helperText=""
                      style={{ width: 300 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem key={enums.Units.KWH} value={enums.Units.KWH}>
                        {enums.Units.KWH}
                      </MenuItem>
                      <MenuItem
                        key={enums.Units.MMBTU}
                        value={enums.Units.MMBTU}
                      >
                        {enums.Units.MMBTU}
                      </MenuItem>
                      <MenuItem
                        key={enums.Units.GRAMS}
                        value={enums.Units.GRAMS}
                      >
                        {enums.Units.GRAMS}
                      </MenuItem>
                      <MenuItem key={enums.Units.KG} value={enums.Units.KG}>
                        {enums.Units.KG}
                      </MenuItem>
                      <MenuItem key={enums.Units.TONS} value={enums.Units.TONS}>
                        {enums.Units.TONS}
                      </MenuItem>
                      <MenuItem
                        key={enums.Units.VOYAGES}
                        value={enums.Units.VOYAGES}
                      >
                        {enums.Units.VOYAGES}
                      </MenuItem>
                    </Field>
                    {organizationFormError && organizationFormError.unit && (
                      <div>
                        <span style={{ fontSize: 10, color: 'red' }}>
                          {organizationFormError.unit}
                        </span>
                      </div>
                    )}
                  </Grid>

                  {isSuperAdmin && (
                    <Grid item xs={12}>
                      <OrganizationMultipleSelect />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                        <Button
                          onClick={submitForm}
                          variant="contained"
                          color="primary"
                          disabled={loading || isSubmitting}
                        >
                          {updatingOrganization
                            ? t('Update organization')
                            : location &&
                              location.state &&
                              location.state.createButtonText
                            ? t(location.state.createButtonText)
                            : t('Create organization')}
                        </Button>
                      </Grid>

                      {updatingOrganization &&
                        formCurrentOrganization &&
                        userOrganization?._id?.toString() !==
                          currentOrganization?._id?.toString() && (
                          <Grid item>
                            {!currentOrganization.archived && isSuperAdmin && (
                              <Button
                                variant={'contained'}
                                color="secondary"
                                disabled={
                                  formCurrentOrganization.archived || loading
                                }
                                onClick={() =>
                                  props.archiveOrganizationStart(
                                    params.organizationId,
                                    navigate
                                  )
                                }
                              >
                                Archive Organization
                              </Button>
                            )}

                            {currentOrganization.archived && (
                              <Button
                                variant={'contained'}
                                color="secondary"
                                disabled={
                                  formCurrentOrganization.archived || loading
                                }
                                onClick={() =>
                                  props.restoreOrganizationStart(
                                    params.organizationId,
                                    navigate
                                  )
                                }
                              >
                                Restore Organization
                              </Button>
                            )}
                          </Grid>
                        )}

                      {(loading || isSubmitting) && (
                        <Grid item>
                          <LoadingCircle />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({ organizationsReducer, router, loginReducer }) => {
  const {
    currentOrganization,
    selectedToMakeBusinessWithOrganizations,
    loading,
    loadingForm,
    localOrganizationPicture,
    parentOrganizations,
    organizationFormError,
    organizations,
  } = organizationsReducer;
  const { location } = router;
  const { isAuthenticated, user } = loginReducer;
  return {
    currentOrganization,
    selectedToMakeBusinessWithOrganizations,
    loading,
    loadingForm,
    location,
    isAuthenticated,
    user,
    parentOrganizations,
    localOrganizationPicture,
    organizationFormError,
    organizations,
  };
};

const mapDispatchToProps = {
  createOrganizationStart,
  updateOrganizationStart,
  archiveOrganizationStart,
  restoreOrganizationStart,
  getOrganizationStart,
  getOrganizationFormStart,
  getOrganizations,
  resetCurrentOrganization,
  getParentOrganizations,
  organizationPictureChanged,
  setOrganizationType,
};

FormOrganization = withTranslation('translations')(FormOrganization);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(FormOrganization))
);
