import { useParams } from 'react-router';

import useCreateOrUpdateProductSlicesCombiner from '../Slices/useCreateOrUpdateProductSlicesCombiner';

const useLoadingResources = () => {
  const { assetId } = useParams();
  const {
    loadingForm,
    loading,
    loadingWells,
    loadingStandards,
    loadingProductTypes,
    currentProduct,
    organizations,
    standards,
    productTypes,
    loadingReferenceFiles,
  } = useCreateOrUpdateProductSlicesCombiner();

  const getLoadingReferenceFileCondition = () => {
    return loadingReferenceFiles;
  };

  const getLoadingCondition = () => {
    return (
      loadingForm ||
      loading ||
      loadingWells ||
      loadingStandards ||
      loadingProductTypes ||
      !currentProduct ||
      (!assetId && currentProduct.displayedName)
    );
  };

  const getNoOrganizationsCondition = () =>
    !organizations || organizations.length < 1;
  const getNoStandardCondition = () =>
    (!loadingStandards && !standards) || standards.length < 1;
  const getNoProductTypesCondition = () =>
    (!loadingProductTypes && !productTypes) || productTypes.length < 1;

  return {
    getLoadingCondition,
    getLoadingReferenceFileCondition,
    getNoOrganizationsCondition,
    getNoStandardCondition,
    getNoProductTypesCondition,
  };
};

export default useLoadingResources;
