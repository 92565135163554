import { useSelector } from 'react-redux';
import { OrganizationsReducerStateType } from 'reducers/organizationsReducer/types';
import { RootState } from 'redux/store';

const useOrganizationsSlices = (): OrganizationsReducerStateType => {
  //Redux Organizations slices
  const {
    organizations,
    businessOrganizations,
    organizationsInAllocateMenuFiltredForSuperAdmin,
    organizationsInTransferMenuFiltredForSuperAdmin,
    currentOrganization,
  }: OrganizationsReducerStateType = useSelector(
    (state: RootState) => state.organizationsReducer
  );
  return {
    currentOrganization,
    organizations,
    businessOrganizations,
    organizationsInAllocateMenuFiltredForSuperAdmin,
    organizationsInTransferMenuFiltredForSuperAdmin,
  };
};

export default useOrganizationsSlices;
