import useSummaryPreviewJSONModalConfig from 'components/Administration/Generic/Form/CustomForms/Blockchain/CheckAttributesForm/JSONCheckAttributes/Configuration/useSummaryPreviewJSONModalConfig';
import 'json-diff-kit/dist/viewer.css';
import { Viewer } from 'json-diff-kit';

const JSONDiffSection = ({}) => {
  const { diffResult, currentJSON, loading } =
    useSummaryPreviewJSONModalConfig();

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : diffResult ? (
        <Viewer
          diff={diffResult}
          indent={3}
          lineNumbers={true}
          highlightInlineDiff={true}
          inlineDiffOptions={{
            mode: 'char',
            wordSeparator: ' ',
          }}
        />
      ) : (
        <>
          <div>No differences to display</div>
          <pre>{JSON.stringify(currentJSON, null, 2)}</pre>
        </>
      )}
    </div>
  );
};

export default JSONDiffSection;
