import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';

import DeprecatedCard from '../DeprecatedCard/DeprecatedCard';

import SummaryContent from './SummaryContent/SummaryContent';
import { SummaryDynamicContentPropsType } from './SummaryContent/SymmaryDynamicContent/SummaryDynamicContent';
import SummaryTitle from './SummaryTitle/SummaryTitle';
import SummaryPreviewJSONProductAttributesButton from './BlockchainCheckAttributes/JSONCheckAttributes/SummaryPreviewJSONProductAttributesButton';

const SummaryCard = (props: SummaryDynamicContentPropsType): JSX.Element => {
  return (
    <GenericGrid isCard isItem xsLength={12}>
      <SummaryTitle />
      <DeprecatedCard />
      <SummaryContent removeHiddenItems={props.removeHiddenItems} />
      <SummaryPreviewJSONProductAttributesButton />
    </GenericGrid>
  );
};

export default SummaryCard;
