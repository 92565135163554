import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { getS3URLForProduct, socket } from 'actions';
import VersionSelectionModal from './VersionSelectionModal';
import historyIcon from '../../../../../../../assets/images/icons/time-white.png';

const SummaryPreviewJSONProductAttributesButton = () => {
  const { currentProduct } = useProductSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [versionModalOpen, setVersionModalOpen] = useState(false);

  useEffect(() => {
    const handleUpdateStart = () => setLoading(true);
    const handleUpdateSuccess = () => setLoading(false);

    socket.on('updateProductJSONInBCStart', handleUpdateStart);
    socket.on('updateProductJSONInBCSuccess', handleUpdateSuccess);

    return () => {
      socket.off('updateProductJSONInBCStart', handleUpdateStart);
      socket.off('updateProductJSONInBCSuccess', handleUpdateSuccess);
    };
  }, []);

  const getProductHistory = async () => {
    if (currentProduct?.productId) {
      await dispatch(getS3URLForProduct(currentProduct.productId));
    }
  };

  const handleClick = async () => {
    await getProductHistory();
    setVersionModalOpen(true);
  };

  return (
    <Box
      sx={{
        marginLeft: 2,
        marginRight: 2.5,
        marginBottom: 2,
        marginTop: 2,
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Check product history">
        <Button
          size="small"
          variant="contained"
          aria-label="history"
          color="primary"
          sx={{ minWidth: 50, position: 'relative' }}
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} sx={{ color: 'white' }} />
          ) : (
            <img
              src={historyIcon}
              alt="Show product's history"
              style={{
                width: 20,
                height: 20,
              }}
            />
          )}
        </Button>
      </Tooltip>

      {versionModalOpen && (
        <VersionSelectionModal
          open={versionModalOpen}
          onClose={() => setVersionModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default SummaryPreviewJSONProductAttributesButton;
