import { useSelector } from 'react-redux';
import { ProductsReducerStateType } from 'reducers/productsReducer/types';
import { RootState } from 'redux/store';

const useProductsSlices = (): ProductsReducerStateType => {
  //Redux Product slices
  const {
    allProducts,
    archivedProducts,
    products,
    currentProduct,
    localProductPicture,
    localProductReferenceFiles,
    displayGoToAdministrationAfterAddingProductModal,
    loading,
    loadingReferenceFiles,
    loadingReferenceFilesData,
    loadingForm,
    productsFormError,
    prodSources,
    selectedWells,
    totalCount,
    referenceDocuments,
  }: ProductsReducerStateType = useSelector(
    (state: RootState) => state.productsReducer
  );
  return {
    allProducts,
    archivedProducts,
    products,
    currentProduct,
    localProductPicture,
    localProductReferenceFiles,
    displayGoToAdministrationAfterAddingProductModal,
    loading,
    loadingReferenceFiles,
    loadingReferenceFilesData,
    loadingForm,
    productsFormError,
    prodSources,
    selectedWells,
    totalCount,
    referenceDocuments,
  };
};

export default useProductsSlices;
