import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import GenericBasicSelect from 'components/GenericComponents/BasicFields/GenericBasicSelect/GenericBasicSelect';
import { SelectChangeEvent } from '@mui/material';

type VersionSelectProps = {
  labelId: string;
  id: string;
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  items: {
    key: string;
    value: string;
    label: string;
  }[];
};

const VersionSelect: React.FC<VersionSelectProps> = ({
  labelId,
  id,
  label,
  value,
  onChange,
  items,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <GenericBasicSelect
        label={label}
        labelId={labelId}
        id={id}
        value={value}
        onChange={onChange}
        items={items}
      />
    </FormControl>
  );
};

export default VersionSelect;
