import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

type GenericBasicSelectProps = {
  labelId: string;
  label?: string;
  id: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  items: {
    key: string;
    value: string;
    label: string;
  }[];
  style?: React.CSSProperties;
};

const GenericBasicSelect = ({
  labelId,
  label,
  id,
  value,
  onChange,
  items,
  style,
}: GenericBasicSelectProps) => (
  <Select
    label={label}
    labelId={labelId}
    id={id}
    name={id}
    value={value}
    onChange={onChange}
    style={style}
  >
    {items.map((item) => (
      <MenuItem key={item.key} value={item.value}>
        {item.label}
      </MenuItem>
    ))}
  </Select>
);

export default GenericBasicSelect;
