/* eslint-disable */
import { AnyAction } from 'redux';
import { enums } from '../../enums';
import { OrganizationsReducerStateType } from './types';

const INITIAL_STATE: OrganizationsReducerStateType = {
  currentOrganization: {
    _id: null,
    name: '',
    identifier: '',
    contactFirstName: '',
    contactLastName: '',
    contactPhoneNumber: '',
    contactEmail: '',
    mailingList: [],
    features: [],
    type: '',
    parentOrganization: null,
    businessOrganizations: [],
    issuerOrganizations: [],
    archived: false,
    unit: 'kWh',
    pictureFileName: '',
  },
  organizationFormError: undefined,
  organizationBalanceAccount: null,
  localOrganizationPicture: null,
  organizations: [],
  archivedOrganizations: [],
  parentOrganizations: [],
  selectedToMakeBusinessWithOrganizations: [],
  selectedType: enums.OrganizationTypes.INDEPENDANT,
  organizationUsers: [],
  organizationProducts: [],
  organizationAssetsConsuming: [],
  error: { message: '', isVisible: false },
  loading: false,
  loadingMailingLists: false,
  mailingLists: [],
  businessOrganizations: [],
  loadingForm: false,
  selected: [],
  page: 0,
  rowsPerPage: 10,
  organizationsInTransferMenuFiltredForSuperAdmin: [],
  organizationsInTransferMenuRender: [],
  organizationsInAllocateMenuFiltredForSuperAdmin: [],
  organizationsInAllocateMenuRender: [],
};

const organizationsReducer = (
  state: OrganizationsReducerStateType = INITIAL_STATE,
  action: AnyAction
) => {
  switch (action.type) {
    case 'GET_BUSINESS_ORGANIZATIONS_SUCCESS':
      return {
        ...state,
        businessOrganizations: action.payload,
      };

    case 'GET_ORGANIZATIONS_START':
      return { ...state, loading: true };

    case 'GET_ARCHIVED_ORGANIZATIONS_START':
      return { ...state, loading: true };

    case 'GET_ORGANIZATIONS_SUCCESS':
      return {
        ...state,
        organizations: action.payload,
        loading: false,
      };

    case 'GET_ARCHIVED_ORGANIZATIONS_SUCCESS':
      return {
        ...state,
        archivedOrganizations: action.payload,
        loading: false,
      };

    case 'GET_ISSUER_ORGANIZATION_BALANCE_ACCOUNT_SUCCESS':
      return { ...state, organizationBalanceAccount: action.payload };

    case 'GET_ORGANIZATIONS_FAIL':
      return { ...state, loading: false };

    case 'GET_ARCHIVED_ORGANIZATIONS_FAIL':
      return { ...state, loading: false };

    case 'GET_PARENT_ORGANIZATIONS_START':
      return { ...state, loading: true };

    case 'GET_PARENT_ORGANIZATIONS_SUCCESS':
      return { ...state, parentOrganizations: action.payload, loading: false };

    case 'GET_PARENT_ORGANIZATIONS_FAIL':
      return { ...state, loading: false };

    case 'GET_ORGANIZATION_MAILING_LISTS_START':
      return { ...state, loadingMailingLists: true, mailingLists: [] };

    case 'GET_ORGANIZATION_MAILING_LISTS_SUCCESS':
      return {
        ...state,
        loadingMailingLists: false,
        mailingLists: action.payload,
      };

    case 'GET_ORGANIZATION_MAILING_LISTS_FAIL':
      return { ...state, loadingMailingLists: true, mailingLists: [] };

    case 'GET_ORGANIZATION_START':
      return {
        ...state,
        loading: true,
        selectedToMakeBusinessWithOrganizations: [],
      };

    case 'GET_ORGANIZATION_SUCCESS':
      let selectedToMakeBusinessWithOrganizations =
        action.payload.businessOrganizations.map((org: any) => org._id);
      return {
        ...state,
        currentOrganization: action.payload,
        selectedToMakeBusinessWithOrganizations,
        loading: false,
      };

    case 'GET_ORGANIZATION_FAIL':
      return { ...state, loading: false };

    case 'GET_ORGANIZATION_CONSUMING_ASSETS_START':
      return { ...state, loading: true };

    case 'GET_ORGANIZATION_CONSUMING_ASSETS_SUCCESS':
      return {
        ...state,
        organizationAssetsConsuming: action.payload,
        loading: false,
      };

    case 'GET_ORGANIZATION_CONSUMING_ASSETS_FAIL':
      return { ...state, loading: false };

    case 'GET_ORGANIZATION_PRODUCTS_START':
      return { ...state, loading: true };

    case 'GET_ORGANIZATION_PRODUCTS_SUCCESS':
      return { ...state, organizationProducts: action.payload, loading: false };

    case 'GET_ORGANIZATION_PRODUCTS_FAIL':
      return { ...state, loading: false };

    case 'GET_ORGANIZATION_USERS_START':
      return { ...state, loading: true, organizationUsers: [] };

    case 'GET_ORGANIZATION_USERS_SUCCESS':
      return { ...state, organizationUsers: action.payload, loading: false };

    case 'GET_ORGANIZATION_USERS_FAIL':
      return { ...state, loading: false, organizationUsers: [] };

    case 'GET_ORGANIZATION_FORM_START':
      return {
        ...state,
        loadingForm: true,
        selectedToMakeBusinessWithOrganizations: [],
      };

    case 'GET_ORGANIZATION_FORM_SUCCESS':
      let selectedToMakeBusinessWithOrganizationsInForm =
        action.payload.businessOrganizations.map((org: any) => org._id);

      return {
        ...state,
        currentOrganization: action.payload,
        selectedToMakeBusinessWithOrganizations:
          selectedToMakeBusinessWithOrganizationsInForm,
        loadingForm: false,
        localOrganizationPicture: null,
      };

    case 'GET_ORGANIZATION_FORM_FAIL':
      return { ...state, loadingForm: false };

    case 'ORGANIZATION_FORM_ERROR':
      return { ...state, organizationFormError: action.payload };

    case 'CREATE_ORGANIZATION_START':
      return { ...state, loading: true };

    case 'CREATE_ORGANIZATION_SUCCESS':
      return {
        ...state,
        loading: false,
        currentOrganization: INITIAL_STATE,
        localOrganizationPicture: null,
      };

    case 'CREATE_ORGANIZATION_FAIL':
      return { ...state, loading: false };

    case 'SET_ORGANIZATION_TO_MAKE_BUSINESS_WITH_START':
      return {
        ...state,
        selectedToMakeBusinessWithOrganizations: action.payload,
      };

    case 'SET_ORGANIZATION_TYPE':
      return { ...state, selectedType: action.payload };

    case 'UPDATE_ORGANIZATION_START':
      return { ...state, loading: true };

    case 'ARCHIVE_ORGANIZATION_START':
      return { ...state, loading: true };

    case 'RESTORE_ORGANIZATION_START':
      return { ...state, loading: true };

    case 'UPDATE_ORGANIZATION_SUCCESS':
      return { ...state, loading: false, localOrganizationPicture: null };

    case 'ARCHIVE_ORGANIZATION_SUCCESS':
      return { ...state, loading: false };

    case 'RESTORE_ORGANIZATION_SUCCESS':
      return { ...state, loading: false };

    case 'UPDATE_ORGANIZATION_FAIL':
      return { ...state, loading: false };

    case 'ARCHIVE_ORGANIZATION_FAIL':
      return { ...state, loading: false };

    case 'RESTORE_ORGANIZATION_FAIL':
      return { ...state, loading: false };

    case 'ORGANIZATION_PICTURE_CHANGED':
      return {
        ...state,
        localOrganizationPicture: action.payload,
      };

    case 'RESET_ORGANIZATION':
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          _id: undefined,
          name: '',
          identifier: '',
          contactFirstName: '',
          contactLastName: '',
          contactPhoneNumber: '',
          contactEmail: '',
          type: enums.OrganizationTypes.INDEPENDANT,
          businessOrganizations: [],
          pictureFileName: '',
        },
        selectedType: enums.OrganizationTypes.INDEPENDANT,
        localOrganizationPicture: null,
      };
    case 'SET_ORGANIZATIONS_IN_TRANSFER_MENU_FILTERED_FOR_SUPER_ADMIN':
      return {
        ...state,
        organizationsInTransferMenuFiltredForSuperAdmin: action.payload,
      };

    case 'SET_ORGANIZATIONS_IN_TRANSFER_MENU_RENDER':
      return {
        ...state,
        organizationsInTransferMenuRender: action.payload,
      };
    case 'SET_ORGANIZATIONS_IN_ALLOCATE_MENU_FILTERED_FOR_SUPER_ADMIN':
      return {
        ...state,
        organizationsInAllocateMenuFiltredForSuperAdmin: action.payload,
      };

    case 'SET_ORGANIZATIONS_IN_ALLOCATE_MENU_RENDER':
      return {
        ...state,
        organizationsInAllocateMenuRender: action.payload,
      };

    default:
      return state;
  }
};

export default organizationsReducer;
