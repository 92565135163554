import { Typography } from '@mui/material';

import Box from '@mui/material/Box';
import LoadingCircle from 'components/commons/LoadingCircle';

const ProductReferenceFilesLoader = ({
  loadingReferenceFilesData,
}: {
  loadingReferenceFilesData: string | undefined;
}): JSX.Element => {
  return (
    <Box sx={styles.container}>
      <LoadingCircle size={24} />
      <Typography variant="body1" style={styles.title}>
        Loading your reference files
      </Typography>
      {loadingReferenceFilesData && (
        <Typography variant="subtitle2" color="grey">
          File {loadingReferenceFilesData}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: 'black',
    marginTop: 20,
  },
};

export default ProductReferenceFilesLoader;
